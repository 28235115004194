<template>
  <!-- pin:data START -->
  <div class="pin-data">
    <div class="description">
      <p v-html="`${$t('login.registerpin')}`"></p>
    </div>

    <!-- form:register:form:pin START -->
    <el-form-item prop="pin">
      <el-input
        class="pin"
        type="number"
        inputmode="numeric"
        :placeholder="`${$t('login.messagepin')} *`"
        v-model="registerFormChild.pin"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-input>
    </el-form-item>
    <!-- form:register:form:pin END -->

    <!-- form:register:form:agbs START -->
    <el-form-item prop="agbs">
      <el-checkbox
        v-model="registerFormChild.agbs"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      >
        {{ `${$t('login.agbs')}. *` }}
        <span>
          (<span
            v-on:click="dialogVisible = true"
            v-html="'AGB Details'"
          ></span>)
        </span>
      </el-checkbox>
    </el-form-item>
    <!-- form:register:form:agbs END -->

    <!-- form:register:form:submit START -->
    <el-form-item>
      <el-button
        class="btn-default"
        type="primary"
        v-on:click="executeRecaptcha"
      >{{ $t('login.register') }}</el-button>

      <p
        class="fake-btn-default"
        v-on:click="backStep(progressActiveIndexChild = -1)"
      >{{ $t('login.back') }}</p>
    </el-form-item>
    <!-- form:register:form:submit END -->

    <!-- form:register:with-account START -->
    <div class="register-with-account">
      <span>{{ $t('login.alreadyhaveanaccount') }}? </span>
      <router-link
        v-on:click="resetForm('registerForm')"
        :to="'/login'"
      >
        {{ $t('login.signin') }}
      </router-link>
    </div>
    <!-- form:register:with-account END -->

    <!-- purchase:products:payback:modal START -->
    <dialog-default
      :title="dialogTitle"
      :headline="dialogHeadline"
      :description="dialogDescription"
      :dialogVisible="dialogVisible"
      :dialogClose="dialogClose"
    />
    <!-- purchase:products:payback:modal END -->
  </div>
  <!-- pin:data END -->
</template>

<script>
export default {
  name: 'pin-data',
  props: {
    registerForm: {
      type: Object,
    },

    validateStep: {
      type: Function,
    },

    executeRecaptcha: {
      type: Function,
    },

    submitForm: {
      type: Function,
    },

    backStep: {
      type: Function,
    },

    resetForm: {
      type: Function,
    },

    globalErrorMessage: {
      type: Boolean,
    },
  },
  components: {
    DialogDefault: () => import('../shared/dialog/Default.vue'),
  },
  data() {
    return {
      registerFormChild: this.registerForm,

      /**
       * Dialog
       */
      dialogVisible: false,
      dialogTitle: this.$t('login.dialog.agbs.title'),
      dialogHeadline: this.$t('login.dialog.agbs.headline'),
      dialogDescription: this.$t('login.dialog.agbs.description'),
    };
  },
  methods: {
    dialogClose() {
      this.dialogVisible = false;
    },
  },
  mounted() {
    this.submitForm();
    this.resetForm();
    this.backStep();
  },
};
</script>
